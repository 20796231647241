import { Container } from '@material-ui/core';
import React from 'react';
import styled from '@emotion/styled';
import TechBox from './techBox';
import BackendIcon from '../../assets/svg/aplikacje-web.svg';
import MobileIcon from '../../assets/svg/aplikacje-mobilne.svg';
import WebsitesIcon from '../../assets/svg/strony-internetowe.svg';
import PWAIcon from '../../assets/svg/publikacja-strony-internetowej.svg';
import CrmErpIcon from '../../assets/svg/crm-erp.svg';
import ConsultingIcon from '../../assets/svg/consult.svg';
import DevOpsIcon from '../../assets/svg/devops.svg';
import theme from '../theme/theme';

const Sh3 = styled.h3`
    text-align: left;
    font-weight: 600;
    span {
        color: ${theme.primary}
    }
`;

const AllServices = () => (
  <Container style={{ marginBottom: '90px' }}>
    <Sh3 style={{ textAlign: 'left', fontWeight: 600 }}>
      All our
      {' '}
      <span>services</span>
    </Sh3>
    <TechBox
      fullWidth
      technologies={[
        {
          icon: <MobileIcon />,
          name: 'Mobile Development',
          link: '/mobile-development/',
        },
        {
          icon: <WebsitesIcon />,
          name: 'Web Development',
          link: '/web-development/',
        },
        {
          icon: <PWAIcon />,
          name: 'Progressive Web Apps',
          link: '/progressive-web-apps/',
        },
        {
          icon: <BackendIcon />,
          name: 'Backend Development',
          link: '/backend-development/',
        },
        {
          icon: <DevOpsIcon />,
          name: 'DevOps Services',
          link: '/devops/',
        },
        {
          icon: <ConsultingIcon />,
          name: 'IT Consulting',
          link: '/consulting-it',
        },
      ]}
    />
  </Container>
);

export default AllServices;
